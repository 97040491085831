import axios from 'axios'
import { VUE_APP_SERVER_ENDPOINT } from '@/app.config'

async function index() {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/record`)
}

async function indexByTreatment(treatmentId) {
  return await axios.get(`${VUE_APP_SERVER_ENDPOINT}/record/treatment/${treatmentId}`)
}

async function dimension(dimension) {
  const json = JSON.stringify(dimension)
  return await axios.post(`${VUE_APP_SERVER_ENDPOINT}/${localStorage.getItem('lang')}/app/dimension`, json)
}

export default {
  index,
  indexByTreatment,
  dimension,
}