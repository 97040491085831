<template lang='pug'>
div(v-resize='onResize', @click='visible = false')
  v-toolbar.mb-6(flat, style='margin-left: -15px; height: 60px')
    img(src='@/assets/title_logo.png', width='50', style='margin-right: 10px; margin-top: 25px', v-if='!isOpen', @click='setWidth')
    v-toolbar-title(v-if='isOpen') {{ typeUsername == "1" ? $t("titlePatient") : $t("titlePatient") }}
    h3(v-if='!isOpen', style='margin-top: 25px; font-size: 19px') {{ typeUsername == "1" ? $t("titlePatient") : $t("titlePatient") }}
    v-divider.mx-4(inset, vertical, v-if='isOpen')
    v-spacer
    div(v-if='isOpen', style='display: flex')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, dark, color='orange', @click.stop='dialog = true', v-bind='attrs', v-on='on')
            v-icon mdi-plus
        span {{ typeUsername == "1" ? $t("addUser") : $t("addPatient") }}
      div(style='width: 20px')
      v-tooltip.batchAdd(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.batchBth(fab, @click.stop='batchDialog = true', v-bind='attrs', v-on='on')
            span.iconfont.icon-piliangtianjia
        span {{ typeUsername == "1" ? $t("batchAddUser") : $t("batchAddPatient") }}
      div(style='width: 20px')
      v-tooltip.batchAdd(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.batchBth(fab, @click.stop='discharge()', v-bind='attrs', v-on='on')
            span.iconfont.icon-ypgl_icon
        span {{ $t("dischargedPatientList")}}
    div(:style='{ position: "relative", top: isOpen ? "0" : "10px", display: "flex" }', v-if='!isOpen')
      v-tooltip(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn(fab, dark, color='orange', @click.stop='dialog = true', v-bind='attrs', v-on='on')
            v-icon mdi-plus
        span {{ typeUsername == "1" ? $t("addUser") : $t("addPatient") }}
      div(style='width: 20px')
      v-tooltip.batchAdd(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.batchBth(fab, @click.stop='batchDialog = true', v-bind='attrs', v-on='on')
            span.iconfont.icon-piliangtianjia
        span {{ typeUsername == "1" ? $t("batchAddUser") : $t("batchAddPatient") }}
      div(style='width: 20px')
      v-tooltip.batchAdd(bottom)
        template(v-slot:activator='{ on, attrs }')
          v-btn.batchBth(fab, @click.stop='discharge()', v-bind='attrs', v-on='on')
            span.iconfont.icon-ypgl_icon
        span {{ $t("dischargedPatientList")}}
  v-row(style='margin-left: -15px; height: 40px; margin-bottom: 30px; width: 400px')
    v-col(cols='12', sm='8', md='8')
      el-input.input-with-select(
        :placeholder="$t('pleaseEnterContent')",
        v-model='userName',
        clearable,
        @clear='inputClear',
        @click.stop.native='isOpen ? (visible = false) : (visible = true)',
        @focus.stop='keyboardShowClick'
      )
        el-select(v-model='select', slot='prepend', :placeholder="$t('selectPlease')", @change='searchType', clearable, @clear='selectClear')
          el-option(:label="$t('name')", value='name')
          el-option(:label='numberName ? numberName : this.$t("number")', value='hospital_number')
          el-option(
            :label='bedNumberName === "true" || bedNumberName == "" ? (typeUsername == 1 ? $t("userNameNumber") : $t("patientNumber")) : bedNumberName',
            value='patient_number',
            v-if='bedNumberName !== "false"'
          )
          el-option(:label='partition === "true" || partition == "" ? $t("subregion") : partition', value='bed_number', v-if='partition !== "false"')
    v-tooltip(bottom)
      template(v-slot:activator='{ on, attrs }')
        v-btn.back(dark, color='orange', @click='searchClick()', v-bind='attrs', v-on='on')
          v-icon.iconfont.icon-31sousuo
      span {{ $t("search") }}
  div(ref='resizableDiv', v-if='isOpen')
    v-data-table(
      fixed-header,
      :height='dataTableHeight',
      :headers='headers',
      :items='patientsList',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }'
    )
      template(v-slot:item.avail='{ item }')
        v-btn(v-if='item.avail', block, rounded, color='primary', @click='start(item)')
          v-icon(left, style='font-size: 22px') mdi-play-circle
          | {{ $t("startTraining") }}
        v-btn(v-else-if='!item.avail', block, rounded, color='warning')
          v-icon(left) mdi-youtube-subscription
          | {{ $t("inTraining") }}
        v-btn(v-else, disabled, rounded, block, color='primary') N/A
      template(v-slot:item.hospitalNumber='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-clipboard-account
          pre.body-1 {{ item.hospitalNumber }}
      template(v-slot:item.bedNumber='{ item }', v-if='partition !== "false"')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-lock-pattern
          pre.body-1 {{ item.bedNumber }}
      template(v-slot:item.patientNumber='{ item }')
        v-row.mx-0(align='center')
          v-icon.mr-2 mdi-bed
          pre.body-1 {{ item.patientNumber }}
      template(v-slot:item.birthday='{ item }', v-if='enableAge') {{ Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) >= 0 ? Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) : "" }}
      template(v-slot:item.model.CreatedAt='{ item }') {{ treatmentDate(item.model.CreatedAt, displayTime) }}
      template(v-slot:item.actions='{ item }')
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn(icon, @click='goToDetail(item)', v-bind='attrs', v-on='on')
              img.bingli(src='@/assets/bingli_icon.png')
          span {{ $t("conditionManagement") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
              span.iconfont.icon-chakan
          span {{ $t("viewreport") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn(icon, @click='edit(item)', v-bind='attrs', v-on='on')
              v-icon mdi-account-edit
          span {{ $t("actionEditTooltip") }}
        span
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                span.iconfont.icon-ypgl_icon(style='font-weight: bold; color: #757575')
            span {{ $t("discharged") }}
        span(v-if='isAdmin')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='deletePatient(item)', v-bind='attrs', v-on='on')
                v-icon mdi-delete
            span {{ $t("delete") }}

  div(ref='resizableDiv', v-if='!isOpen')
    v-data-table.elevation-1.elevation-1(
      fixed-header,
      :height='visible && enableTouch ? 750 : dataTableHeight',
      :headers='headers',
      :items='patientsList',
      :search='search',
      :items-per-page='itemsPerPage',
      :footer-props='{ itemsPerPageOptions: [itemsPerPage] }',
      :expanded='expanded',
      item-key='model.ID',
      @click:row='clicked'
    )
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan='headers.length', style='background: #f9f7f7; border-top: none')
          v-row(style='font-size: 12px; display: flex; justify-content: space-between; align-items: center; width: 100%')
            div
              span(style='position: relative; left: 20px; display: inline-block; width: 80px') {{ $t("marriage") }}：{{ item.maritalStatus }}
              span(style='position: relative; left: 80px; width: 80px') {{ $t("nationality") }}：{{ item.ethnicity }}
            div
              span(v-if='!isAdmin') {{ $t("createTime") }}：
              span(:style='{ marginLeft: isAdmin ? "420px" : "0px", display: "inline-block", width: "105px" }') {{ treatmentDate(item.model.CreatedAt, displayTime) }}
            div(style='width: 10px; display: flex; flex-direction: column; position: relative; right: 18px')
              span(:style='{ position: "relative", top: isAdmin ? "5px" : "2px" }', v-if='isAdmin')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='edit(item)', v-bind='attrs', v-on='on')
                      v-icon mdi-account-edit
                  span {{ $t("actionEditTooltip") }}
              span(style='position: relative; top: -5px')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='remove(item)', v-bind='attrs', v-on='on')
                      span.iconfont.icon-ypgl_icon(style='font-weight: bold; color: #757575')
                  span {{ $t("discharged") }}
              span(:style='{ position: "relative", top: isAdmin ? "-13px" : "-10px" }', v-if='isAdmin')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='goToDetail(item)', v-bind='attrs', v-on='on')
                      img.bingli(src='@/assets/bingli_icon.png', style='width: 20px')
                  span {{ $t("conditionManagement") }}
              span(v-if='isAdmin', style='position: relative; top: -15px')
                v-tooltip(bottom)
                  template(v-slot:activator='{ on, attrs }')
                    v-btn(icon, @click='deletePatient(item)', v-bind='attrs', v-on='on')
                      v-icon mdi-delete
                  span {{ $t("delete") }}

      template(v-slot:item.avail='{ item }')
        v-btn(v-if='item.avail', block, rounded, color='primary', @click='start(item)', style='width: 100px; height: 30px')
          v-icon(left, style='font-size: 20px') mdi-play-circle
          | {{ $t("startTraining") }}
        v-btn(v-else-if='!item.avail', block, rounded, color='warning')
          v-icon(left) mdi-youtube-subscription
          | {{ $t("inTraining") }}
        v-btn(v-else, disabled, rounded, block, color='primary') N/A
      template(v-slot:item.birthday='{ item }') {{ Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) > 0 ? Math.floor((new Date() - new Date(item.birthday).getTime()) / 3.15576e10) : "" }}
      template(v-slot:item.model.CreatedAt='{ item }', v-if='isAdmin') {{ treatmentDatePickUp(item.model.CreatedAt) }}
      template(v-slot:item.actions='{ item }')
        span(v-if='!isAdmin')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='goToDetail(item)', v-bind='attrs', v-on='on')
                img.bingli(src='@/assets/bingli_icon.png', style='width: 20px')
            span {{ $t("conditionManagement") }}
        v-tooltip(bottom)
          template(v-slot:activator='{ on, attrs }')
            v-btn(icon, @click='print(item)', v-bind='attrs', v-on='on')
              v-icon mdi-printer
          span {{ $t("actionPrintTooltip") }}
        span(v-if='!isAdmin')
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon, @click='edit(item)', v-bind='attrs', v-on='on')
                v-icon mdi-account-edit
            span {{ $t("actionEditTooltip") }}

  v-dialog(v-model='dialog', width='800', height='200', persistent, @click:outside='visible = false')
    <!-- (:style="{position: 'relative', top : isOpen ? 'auto' : '-100px'}") -->
    v-card
      v-card-title.primary.headline.mb-12
        h4(style='color: white') {{ $t("daTitle") }}
      v-card-text
        v-form(ref='form', v-model='valid', :class='isOpen ? "cDialog" : "mDialog"')
          v-row
            v-col(cols='12', sm='6', md='6')
              v-text-field(
                v-model='newItem.name',
                :label='$t("name")',
                solo,
                prepend-inner-icon='mdi-account',
                :rules='rules.nameRules',
                maxlength='20',
                counter,
                required,
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='3', md='3')
              v-text-field(
                v-model='newItem.hospitalNumber',
                :label='numberName ? numberName : this.$t("number")',
                :rules='rules.patientNumberRules',
                solo,
                prepend-inner-icon='mdi-clipboard-account',
                maxlength='15',
                counter,
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='3', md='3', v-show='partition !== \'false\'')
              v-text-field(
                v-model='newItem.bedNumber',
                :label='partition === "true" || partition == "" ? $t("subregion") : partition',
                solo,
                prepend-inner-icon='mdi-lock-pattern',
                maxlength='10',
                counter,
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='6', md='3')
              v-select(
                :items='genders',
                v-model='newItem.gender',
                :label='$t("daGender")',
                solo,
                prepend-inner-icon='mdi-gender-male-female',
                :rules='rules.genderRules',
                required
              )
            v-col(cols='12', sm='6', md='3', v-if='enableAge')
              v-text-field(
                v-model='newItem.birthday',
                :label='$t("daAge")',
                solo,
                prepend-inner-icon='mdi-calendar',
                maxlength='3',
                counter,
                type='number',
                :min='1',
                onkeyup='this.value=this.value.replace(/\D|^0/g,\'\')',
                onafterpaste='this.value=this.value.replace(/\D|^0/g,\'\')',
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='3', md='3', v-show='bedNumberName !== "false"')
              v-text-field(
                v-model='newItem.patientNumber',
                :label='bedNumberName === "true" || bedNumberName == "" ? (typeUsername == "1" ? $t("daUserNameNumber") : $t("daPatientNumber")) : bedNumberName',
                solo,
                prepend-inner-icon='mdi-bed',
                maxlength='10',
                counter,
                required,
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              ) 
            v-col(cols='12', :sm='isOpen ? 6 : 3', md='3')
              v-select(
                :items='maritalStatuses',
                v-model='newItem.maritalStatus',
                :label='$t("daMarital")',
                solo,
                prepend-inner-icon='mdi-human-male-female'
              )
            //- v-col(cols='12', :sm='isOpen ? 6 : 3', md='3')
            //-   v-select(
            //-     :items='ethnicityOptions',
            //-     v-model='newItem.ethnicity',
            //-     :label='$t("daEthnicity")',
            //-     solo,
            //-     prepend-inner-icon='mdi-map-marker'
            //-   )
            v-col(cols='12', :sm='isOpen ? 6 : 3', md='')
              v-select(
                :items='educationOptions',
                v-model='newItem.education',
                :label='$t("daEducation")',
                solo,
                prepend-inner-icon='mdi-school'
              )

            v-col(cols='12', :sm='isOpen ? 6 : 12', md='6')
              v-text-field(
                v-model='newItem.idNumber',
                :label='$t("daId")',
                solo,
                prepend-inner-icon='mdi-identifier',
                maxlength='20',
                counter,
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              )
            v-col(cols='12', sm='12', md='12')
              v-textarea(
                v-model='newItem.remarks',
                :label='$t("daRemarks")',
                solo,
                prepend-inner-icon='mdi-pencil-box',
                auto-grow,
                maxlength='300',
                counter,
                @click.stop.native='isOpen ? (visible = false) : (visible = true)',
                @focus.stop='keyboardShowClick'
              )
      v-card-actions
        v-spacer
        v-btn(text, @click='clear(); visible = false') {{ $t("daClear") }}
        v-btn(text, @click='clear(); dialog = false; visible = false') {{ $t("daCancel") }}
        v-btn(text, @click='save(); visible = false') {{ $t("daSave") }}

  v-dialog(v-model='confirmDialog', @click:outside='confirmCancel()', width='500')
    v-card
      v-card-title.warning.headline.mb-6
        h4(style='color: white') {{ type == "discharge" ? $t('dischargeProcessingTips') : $t('drConfirm') }}
      v-card-text
        h3(:style='{ textIndent: type == "discharge" ? "2em" : "", lineHeight: "30px" }') {{ type == "discharge" ? message : deleteMsg }}
        h3(style='text-indent: 2em; margin-top: 10px', v-if='type == "discharge"') {{ $t("Thanks") }}
      v-card-actions
        v-spacer
        v-btn(text, @click='confirmCancel()') {{ $t("drCancel") }}
        v-btn(text, @click='confirmConfirm()') {{ $t("drConfirm") }}

  v-snackbar(elevation='24', v-model='snackbar', :color='snackbarColor', timeout='3000') {{ notifMsg }}
    template(v-slot:action='{ attrs }')
      v-btn(text, v-bind='attrs', @click='snackbar = false') {{ $t("notifClose") }}

  StartTreatment(ref='startTreatment')
  BatchAddDialog(
    :batchDialog='batchDialog',
    :typeUsername='typeUsername',
    @cancelClick='cancelHandleClick',
    @closeDialog='closeDialogClick'
  )
  PrintDialog(:printDialog='printDialog', :printId='printId', @cancelClick='cancelClick', @printClick='printHandleClick')
  Keyboard#keyboard(layout='normal', :input='input', v-if='visible && enableTouch', :cancel='cancel')
  .nav
    NavigationDrawer(:class='navShow ? "navShow" : "navHide"', @selectClick='selectClick')
  .mask(v-show='navShow && !isOpen', @click='outClick')
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { patient, app } from '../api'
import StartTreatment from './patients/startTreatment.vue'
import BatchAddDialog from './patients/BatchAddDialog.vue'
import PrintDialog from './patients/PrintDialog.vue'
import Keyboard from 'vue-keyboard-zh'
import { NavigationDrawer } from '../components'

export default {
  data: () => ({
    dialog: false,
    batchDialog: false,
    printDialog: false,
    newItem: {},
    valid: true,
    genders: {},
    maritalStatuses: {},
    ethnicityOptions: [
      '汉族',
      '满族',
      '蒙古族',
      '回族',
      '藏族',
      '维吾尔族',
      '苗族',
      '彝族',
      '壮族',
      '布依族',
      '侗族',
      '瑶族',
      '白族',
      '土家族',
      '哈尼族',
      '哈萨克族',
      '傣族',
      '黎族',
      '傈僳族',
      '佤族',
      '畲族',
      '高山族',
      '拉祜族',
      '水族',
      '东乡族',
      '纳西族',
      '景颇族',
      '柯尔克孜族',
      '土族',
      '达斡尔族',
      '仫佬族',
      '羌族',
      '布朗族',
      '撒拉族',
      '毛南族',
      '仡佬族',
      '锡伯族',
      '阿昌族',
      '普米族',
      '朝鲜族',
      '塔吉克族',
      '怒族',
      '乌孜别克族',
      '俄罗斯族',
      '鄂温克族',
      '德昂族',
      '保安族',
      '裕固族',
      '京族',
      '塔塔尔族',
      '独龙族',
      '鄂伦春族',
      '赫哲族',
      '门巴族',
      '珞巴族',
      '基诺族',
      '其他'
    ],
    educationOptions: {},
    birthdayMenu: false,

    dataTableHeight: 0,
    itemsPerPage: 0,
    search: '',
    confirmDialog: false,
    patientIdDel: '',
    patientIdDischarge: '',
    snackbar: false,
    snackbarColor: '',
    notifMsg: '',
    printContentList: {},
    printContent: [],
    userName: '',
    patientsList: [],
    treatmentsData: [],
    scoreStatus: '',
    deviceObject: {},
    select: 'name',
    removeApp: {},
    getData: [],
    appsData: [],
    categoryType: '0,1,2,3,4,5,6,7,8',
    isOpen: false,
    expanded: [],
    input: '',
    visible: false,
    navShow: false,
    printId: 0,
    type: '',
    message: '',
    deleteMsg: ''
  }),

  watch: {
    birthdayMenu(val) {
      val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'))
    },
    patientsWA(val) {
      if (this.userName == '') {
        this.patientsList = val
      } else {
        this.patientsList = this.patientsList.map(x => {
          const patient = val.find(y => y.model.ID === x.model.ID)
          if (patient !== undefined) {
            return patient
          }
        })
      }
    }
  },
  async mounted() {
    this.resize()
    this.genders = [this.$t("male"), this.$t("female")]
    this.maritalStatuses = [this.$t("unmarried"), this.$t("married"), this.$t("divorced"), this.$t("widowed")]
    this.educationOptions = [this.$t("none"), this.$t("primary"), this.$t("junior"), this.$t("highSchool"), this.$t("university"), this.$t("master"), this.$t("PhD")]
    window.addEventListener('resize', this.resize, false)

    const appResult = await app.index(this.getLocalStorage1(this.categoryType, 1))
    this.appsData = appResult.data
    this.getPatients()
  },

  methods: {
    async goToDetail(item) {
      this.$router.push({
        path: `/patients/${item?.model.ID}`,
        query: { type: 'desease', hospital: '' }
      })
    },
    discharge() {
      this.$router.push({
        path: '/discharge'
      })
    },
    setWidth() {
      this.navShow = true
    },
    ...mapActions({
      patientsListWA: 'patients/index'
    }),
    getPatients() {
      this.patientsListWA()
    },
    selectClick(val) {
      if (!this.isOpen) {
        this.navShow = val
      } else {
        this.navShow = true
      }
    },
    outClick() {
      if (this.isOpen) {
        this.navShow = true
      } else {
        this.navShow = false
      }
    },
    clicked(value) {
      const index = this.expanded.indexOf(value)
      if (index === -1 && this.expanded.length === 0) {
        this.expanded.push(value)
      } else if (index == 0) {
        this.expanded.splice(index, 1)
      } else if (this.expanded.length !== 0) {
        this.expanded.splice(index, 1)
        //打开点击的行
        this.expanded.push(value)
      }
    },
    keyboardShowClick(e) {
      if (this.isOpen) {
        this.input = ''
        this.visible = false
      } else {
        this.input = e.target
        if (!this.visible) this.visible = true
      }
    },
    cancel() {
      this.visible = false
    },
    resize() {
      let w_width = window.innerWidth
      if (w_width <= 1080) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    treatmentDate(dateString, displayTime = true) {
      const date = new Date(dateString)
      if (displayTime) {
        return (
          date.toLocaleDateString('zh-CN', { hour12: false }) +
          ' ' +
          date.toLocaleTimeString('zh-CN', {
            hour: '2-digit',
            minute: '2-digit'
          })
        )
      } else {
        return date.toLocaleDateString('zh-CN', { hour12: false })
      }
    },
    treatmentDatePickUp(dateString) {
      const date = new Date(dateString)
      const month = date.getMonth() + 1
      const days = date.getDate()
      return month + '/' + days
    },
    getLocalStorage1(query, show) {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role,
        isShow: show,
        type: String(query)
      }
      return obj1
    },
    getLocalStorage() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const obj1 = {
        adminId: role == 'admin' ? adminId : 1,
        userId: userId,
        role: role
      }
      return obj1
    },
    inputClear() {
      this.userName = ''
      this.searchClick()
    },
    selectClear() {
      this.select = ''
      this.searchClick()
    },
    searchType(value) {
      this.select = value
    },

    async searchClick() {
      const logToken = JSON.parse(window.localStorage.getItem('vuex'))
      const adminId = logToken.users.userId
      const role = logToken.users.role
      const userId = parseInt(
        role == 'admin' ? (window.localStorage.getItem('userId') ? window.localStorage.getItem('userId') : 1) : adminId
      )
      const patientList = await patient.index(this.select, this.userName, userId)
      this.patientsList =
        patientList.data == null
          ? []
          : patientList.data.map(x => {
            const patient = this.patientsWA.find(y => y.model.ID === x.model.ID)
            return patient
          })
    },
    onResize() {
      const height = window.innerHeight - this.$refs.resizableDiv.getBoundingClientRect().y - 12 - 37
      this.dataTableHeight = height
      this.itemsPerPage = parseInt((height - 48) / 48)
    },

    clear() {
      this.$refs.form.reset()
      this.newItem = {}
    },
    cancelHandleClick() {
      this.batchDialog = false
    },
    closeDialogClick() {
      this.getPatients()
      this.batchDialog = false
    },

    cancelClick() {
      this.printDialog = false
    },
    addBtnClick() {
      this.dialog = true
      this.batchShow = true
    },

    batchAdd() {
      this.addType = 2
    },
    async save() {
      var birthday = ''
      if (this.newItem.birthday !== undefined) {
        const year = new Date().getFullYear() - Number(this.newItem.birthday)
        birthday = year + '-' + '01' + '-' + '01'
      } else {
        birthday = '2099-06-01'
      }
      this.$refs.form.validate()
      if (this.valid) {
        const p = {
          ...this.newItem,
          birthday: birthday + 'T00:00:00Z',
          status: true,
          ...this.getLocalStorage()
        }
        if ('model' in this.newItem) {
          try {
            await patient.edit(p)
            this.snackbarColor = 'success'
            this.notifMsg = `${this.$t('notifSuccess')}`
          } catch (err) {
            this.snackbarColor = 'error'
            this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
          }
        } else {
          try {
            await patient.add(p)
            this.snackbarColor = 'success'
            this.notifMsg = `${this.$t('notifSuccess')}`
          } catch (err) {
            this.snackbarColor = 'error'
            this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
          }
        }
        this.getPatients()
        this.snackbar = true
        this.confirmCancel()
        this.clear()
        this.dialog = false
      }
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date)
    },
    start(item) {
      this.$refs.startTreatment.prePatientId = item?.model.ID
      this.$refs.startTreatment.dialog = true
    },
    // 查看报告
    async print(item) {
      this.printId = item?.model.ID
      this.printContentList = item
      this.printDialog = true
      this.printContent = []
    },
    async printHandleClick(rangeVal, printId, diagnosis, plan) {
      this.$router.push({
        path: `/patients/${printId}`,
        query: { type: 'print', rangeVal: JSON.stringify(rangeVal), hospital: '', diagnosis: diagnosis, plan: plan },
        params: { diagnosis: diagnosis, plan: plan }
      })
    },
    edit(item) {
      let age = new Date().getFullYear() - parseInt(item.birthday.slice(0, 4))
      this.newItem = {
        ...item,
        birthday: age >= 0 ? age : ''
      }
      this.dialog = true
    },
    remove(item) {
      this.type = 'discharge'
      this.confirmDialog = true
      this.patientIdDischarge = item?.model.ID
      this.message = this.$t('handleDischargeVerificationInformation', { name: item.name })

    },
    deletePatient(item) {
      this.type = 'delete'
      this.confirmDialog = true
      this.patientIdDel = item?.model.ID
      this.deleteMsg = this.$t('deleteUser', { name: item.name })
    },
    confirmCancel() {
      this.confirmDialog = false
    },
    async confirmConfirm() {
      try {
        if (this.type == 'discharge') {
          await patient.remove(this.patientIdDischarge)
          this.snackbarColor = 'success'
          this.notifMsg = `${this.$t('notifSuccess')}`
          this.getPatients()
        } else {
          await patient.delDischarge(this.patientIdDel)
          this.snackbarColor = 'success'
          this.notifMsg = `${this.$t('notifSuccess')}`
          this.getPatients()
        }
      } catch (err) {
        this.snackbarColor = 'error'
        this.notifMsg = `${this.$t('notifFailure')}${err.message}!`
      }
      this.snackbar = true
      this.confirmCancel()
    },
    clearSnackbar() {
      this.notifMsg = ''
      this.snackbarColor = ''
    }
  },

  components: {
    StartTreatment,
    BatchAddDialog,
    PrintDialog,
    Keyboard,
    NavigationDrawer
  },

  computed: {
    ...mapGetters({
      isAdmin: 'users/isAdmin',
      patientsWA: 'patients/patientsWA',
      institution: 'setting/institution',
      reportName: 'setting/reportName',
      treatmentsByPatientId: 'treatments/treatmentsByPatientId',
      partition: 'setting/enabledPartition',
      typeUsername: 'setting/userName',
      treatments: 'treatments/treatmentsWADP',
      enableScoreOverwrite: 'setting/enableScoreOverwrite',
      displayTime: 'setting/displayTime',
      treatmentTime: 'setting/treatmentTime',
      logo: 'setting/logo',
      bedNumberName: 'setting/bedNumberName',
      numberName: 'setting/numberName',
      enableEndTime: 'setting/enableEndTime',
      enableTouch: 'setting/enableTouch',
      enableAge: 'setting/enableAge'
    }),

    ...mapState({
      patients: state => state.patients.items,
      devices: state => state.devices.items,
      apps: state => state.apps.items
    }),

    headers() {
      const headers = []
      if (this.partition !== 'false') {
        headers.push(
          {
            text: this.$t('thAvailability'),
            value: 'avail',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('name'),
            value: 'name',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName ? this.numberName : this.$t('number'),
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.partition === 'true' || this.partition == '' ? this.$t('subregion') : this.partition,
            value: 'bedNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
              text:
                this.bedNumberName === 'true' || this.bedNumberName == ''
                  ? this.typeUsername == '1'
                    ? this.$t('thUserNameNumber')
                    : this.$t('thPatientNumber')
                  : this.bedNumberName,
              value: 'patientNumber',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          {
            text: this.$t('thGender'),
            value: 'gender',
            align: this.isOpen ? 'start' : 'center'
          },
          this.enableAge
            ? {
              text: this.$t('thAge'),
              value: 'birthday',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          this.isAdmin
            ? {
              text: this.$t('thCreated'),
              value: 'model.CreatedAt',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          {
            text: this.$t('thActions'),
            value: 'actions',
            sortable: false,
            align: this.isOpen ? 'end' : 'center'
          }
        )
      } else {
        headers.push(
          {
            text: this.$t('thAvailability'),
            value: 'avail',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('name'),
            value: 'name',
            sortable: false,
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.numberName ? this.numberName : this.$t('number'),
            value: 'hospitalNumber',
            align: this.isOpen ? 'start' : 'center'
          },
          this.bedNumberName != 'false'
            ? {
              text:
                this.bedNumberName === 'true' || this.bedNumberName == ''
                  ? this.typeUsername == '1'
                    ? this.$t('thUserNameNumber')
                    : this.$t('thPatientNumber')
                  : this.bedNumberName,
              value: 'patientNumber',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          {
            text: this.$t('thGender'),
            value: 'gender',
            align: this.isOpen ? 'start' : 'center'
          },
          this.enableAge
            ? {
              text: this.$t('thAge'),
              value: 'birthday',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          {
            text: this.$t('thMarital'),
            value: 'maritalStatus',
            align: this.isOpen ? 'start' : 'center'
          },
          {
            text: this.$t('thEthnicity'),
            value: 'ethnicity',
            align: this.isOpen ? 'start' : 'center'
          },
          this.isAdmin
            ? {
              text: this.$t('thCreated'),
              value: 'model.CreatedAt',
              align: this.isOpen ? 'start' : 'center'
            }
            : '',
          {
            text: this.$t('thActions'),
            value: 'actions',
            sortable: false,
            align: this.isOpen ? 'end' : 'center'
          }
        )
      }
      return headers
    },

    rules() {
      let rules = {}
      if (this.typeUsername == '1') {
        rules = {
          nameRules: [v => !!v || this.$t('daNameRule')],
          patientNumberRules: [v => !!v || `${this.numberName ? this.numberName : this.$t('number')}${this.$t("mustRequired")}`],
          bedNumberRules: [],
          genderRules: [v => !!v || this.$t('daGenderRule')],
          birthdayRules: [v => !!v || this.$t('daBirthdayRule'), v => /^([1-9]\d?|1[01]\d|120)$/.test(v) || this.$t('ageMax')]
        }
      } else {
        rules = {
          nameRules: [v => !!v || this.$t('daNameRule')],
          patientNumberRules: [v => !!v || `${this.numberName ? this.numberName : this.$t('number')}${this.$t("mustRequired")}`],
          bedNumberRules: [],
          genderRules: [v => !!v || this.$t('daGenderRule')],
          birthdayRules: [v => !!v || this.$t('daBirthdayRule'), v => /^([1-9]\d?|1[01]\d|120)$/.test(v) || this.$t('ageMax')]
        }
      }
      return rules
    }
  }
}
</script>

<style scoped lang="scss">
.batchDelete {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 7px;
  color: #1976d2;
  cursor: pointer;
  position: relative;
  top: -20px;
  left: 20px;
}

.batchAdd {
  position: relative;
}

.batchBth {
  background: orange !important;
}

.icon-piliangtianjia {
  color: #fff;
}

.icon-ypgl_icon {
  color: #fff;
  font-size: 25px;
}

.addBtn {
  width: 35px;
  position: absolute;
  right: 6px;
}

.back {
  margin-top: 11px;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  padding: 0;
  min-width: 45px;
  height: 40px;
}

::v-deep .el-select .el-input {
  width: 100px;
}

::v-deep .el-select>.el-input {
  border-top: 1px solid #9e9e9e;
  border-left: 1px solid #9e9e9e;
  border-bottom: 1px solid #9e9e9e;
  height: 39.5px;
}

::v-deep .el-input .el-input__inner:focus {
  border-color: none !important;
  border: 2px solid #1976d2 !important;
}

::v-deep .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

::v-deep .el-input__inner {
  border: 1px solid #9e9e9e;
}

.icon-fenquguanli {
  font-size: 26px;
  margin-right: 8px;
  color: #757575;
}

.bingli {
  width: 25px;
}

.elevation-1 {
  width: 100%;
}

::v-deep .elevation-1>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0 8px !important;
}

::v-deep .elevation-1>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px !important;
}

::v-deep .elevation-1>.v-data-table__wrapper>table>thead>tr>th>.v-data-table-header__icon {
  font-size: 8px !important;
  position: relative;
  top: 1px;
}

#keyboard {
  position: absolute;
  top: auto !important;
  bottom: 0;
  z-index: 999;
  padding: 14px 21px;
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 0px;
}

::v-deep .mDialog {
  max-height: 300px;
  overflow-y: auto;
}

::v-deep .cDialog {
  max-height: 100%;
}

::v-deep .elevation-1>.v-data-table__wrapper tbody tr.v-data-table__expanded {
  background: #f9f7f7 !important;
}

::v-deep .theme--light.elevation-1>.v-data-table__wrapper>table>tbody>tr.v-data-table__expanded>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

::v-deep .elevation-1 {
  box-shadow: none !important;
}

.navShow {
  animation: expanded 0.5s ease;
}

.navHide {
  display: none;
}

.mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 998;
}

.icon-chakan {
  font-weight: bold;
  font-size: 22px;
  color: #757575;
}
</style>
